var presentAfterpay = presentAfterpay || {};
var presentClearpay = presentClearpay || {};

(function ($) {
  Drupal.behaviors.skuList = {
    attach: function (context) {
      var isAfterpayReady = $.isEmptyObject(presentAfterpay) === false && typeof presentAfterpay === 'object' ? true : false;
      var isClearpayReady = $.isEmptyObject(presentClearpay) === false && typeof presentClearpay === 'object' ? true : false;
      var activateButton = function ($skuListItems, skuBaseId) {
        if (!!$skuListItems.length && !!skuBaseId) {
          $skuListItems
            .removeClass('button--active')
            .filter('[data-sku-base-id=' + skuBaseId + ']')
            .addClass('button--active');
        }
      };
      var afterPayPrice = function ($skuPrice, $skuUnitPrice) {
        var amount;
        var amountClearpay;

        if (isAfterpayReady && $skuPrice.length > 0) {
          amount = $skuPrice.html().replace(/[^\d]/g, '');
          presentAfterpay?.afterpayConfig(amount);
        }
        if (isClearpayReady && $skuUnitPrice.length > 0) {
          amountClearpay = $skuUnitPrice.html().replace(/[^\d]/g, '');
          presentClearpay?.clearpayConfig(amountClearpay);
        }
      };
      var $skuLists = $('.js-sku-list--v1', context);

      $skuLists.each(function () {
        var $template = $(this);
        var $skuListItems = $template.find('.js-sku-list__button');
        var skuCount = $skuListItems.length;
        var $product = $template.closest('.js-product');
        var skuBaseId = $product.data('sku-base-id');
        var productId = $product.data('product-id');

        // Set initial active state
        activateButton($skuListItems, skuBaseId);

        if (skuCount === 1) {
          $template.hide();
          return;
        }

        $skuListItems.once().click(function (event) {
          event.preventDefault();
          var $item = $(this);
          var skuBaseId = $item.data('sku-base-id');
          var $skuPrice = $('.js-sku-price', context);
          var $skuUnitPrice = $('.js-sku-unit-price', context);
          var $productEl = $(".js-product[data-product-id='" + productId + "']", context);
          var $skuListItems = $productEl.find('.js-sku-list__button');

          if (!skuBaseId) {
            return;
          }

          activateButton($skuListItems, skuBaseId);
          $productEl.trigger('product.skuSelect', [skuBaseId]);

          afterPayPrice($skuPrice, $skuUnitPrice);
        });
      });
    }
  };
})(jQuery);
